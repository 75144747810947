import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SayHi from "../components/sayhi";
import BottomNav from "../components/bottom-nav";

export default () => {
  return (
    <Layout indexPage={false}>
      <SEO title="Contact me" keywords={["contact", "contact me"]} />
      <div className="flex flex-col justify-between h-full">
        <div />
        <SayHi />
        <div className="mx-auto">
          <BottomNav />
        </div>
      </div>
    </Layout>
  );
};
